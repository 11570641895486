import { ChannelType, DocumentType } from '@innedit/innedit-type';
import { ChannelData } from 'packages/innedit';
import { useEffect, useState } from 'react';

import { ActionProps } from '~/components/Actions/props';
import IconCharBar from '~/icons/CharBar';
import IconCog from '~/icons/Cog';
import IconFile from '~/icons/File';

const useChannel = ({
  admin,
  espaceId,
  channelId,
  pathname,
}: {
  admin?: boolean;
  channelId: string;
  espaceId?: string;
  pathname: string;
}): [
  DocumentType<ChannelType> | undefined,
  (ActionProps | ActionProps[])[] | undefined,
] => {
  const [channel, setChannel] = useState<DocumentType<ChannelType>>();

  useEffect(() => {
    let isMounted = true;

    const channelModel = new ChannelData({ espaceId });
    channelModel.watchById(channelId, snapshot => {
      if (isMounted) {
        setChannel(snapshot);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [channelId]);

  if (!channel) {
    return [undefined, undefined];
  }

  const items: (ActionProps | ActionProps[])[] = [];

  let prefix = admin ? '/admin' : ``;
  if (espaceId) {
    prefix += `/espaces/${espaceId}`;
  }
  prefix += `/channels/${channelId}`;

  const isActive = (to: string): string | undefined => {
    const path = `${prefix}/${to}`;

    return pathname.slice(0, path.length) === path ? 'is-active' : undefined;
  };

  if (admin) {
    // items.push({
    //   className: isActive(`css`),
    //   icon: IconCss3Alt,
    //   label: 'CSS',
    //   to: `${prefix}/css`,
    // });
    items.push({
      className: isActive(`update`),
      icon: IconCog,
      label: 'Paramètres',
      to: `${prefix}/update`,
    });
    // items.push({
    //   className: isActive(`variants`),
    //   icon: IconFill,
    //   label: 'Déclinaisons',
    //   to: `${prefix}/variants`,
    // });

    // items.push({
    //   className: isActive(`redirects`),
    //   icon: IconDirections,
    //   label: 'Redirections',
    //   to: `${prefix}/redirects`,
    // });
  }

  switch (channel.kind) {
    case 'api': {
      return [channel, undefined];
    }

    case 'profile':
    case 'website': {
      if (!admin) {
        items.push({
          className: isActive(`pages`),
          icon: IconFile,
          label: 'Pages',
          to: `${prefix}/pages`,
        });
        // items.push({
        //   className: isActive(`banners`),
        //   icon: IconInfoCircle,
        //   label: 'Bannières',
        //   to: `${prefix}/banners`,
        // });
        items.push({
          className: isActive(`analytics`),
          icon: IconCharBar,
          label: 'Analytics',
          to: `${prefix}/analytics`,
        });
        // items.push({
        //   className: isActive(`update`),
        //   icon: IconCog,
        //   label: 'Préférences',
        //   to: `${prefix}/update`,
        // });
      }

      break;
    }

    default:
  }

  return [channel, items];
};

export default useChannel;
